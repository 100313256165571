import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const candidate_assessment_routes: RouteConfig = {
  path: "assessments",
  component: () =>
    import("@/views/candidate/assessment/CandidateAssessmentsIndex.vue"),
  meta: {
    user_state: [UserState.CANDIDATE]
  },
  children: [
    {
      path: "",
      redirect: "personality"
    },
    {
      path: "personality",
      component: () =>
        import(
          "@/views/candidate/assessment/personality/PersonalityAssessmentWrapper.vue"
        ),
      meta: {
        user_state: [UserState.CANDIDATE],
        name: "nav-sidebar.personality-assessment",
        icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
        credit_logo: require("@/assets/logos/principles-us.svg")
      },
      children: [
        {
          path: "",
          name: "candidate-assessment",
          component: () =>
            import(
              "@/views/candidate/assessment/personality/CandidateAssessment.vue"
            ),
          meta: {
            user_state: [UserState.CANDIDATE],
            name: "nav-sidebar.personality-assessment",
            icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
            credit_logo: require("@/assets/logos/principles-us.svg")
          }
        },
        {
          path: "success",
          name: "assessment-completed",
          component: () =>
            import(
              "@/views/candidate/assessment/personality/CandidateAssessmentComplete.vue"
            ),
          meta: {
            user_state: [UserState.CANDIDATE],
            name: "nav-sidebar.personality-assessment",
            icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
            credit_logo: require("@/assets/logos/principles-us.svg")
          }
        },
        {
          path: "report/:assessment_id",
          name: "assessment-report",
          component: () => import("@/views/shared/AssessmentReport.vue"),
          meta: {
            user_state: [UserState.CANDIDATE],
            name: "nav-sidebar.personality-assessment-report",
            icon: require("@/assets/icons/nav_sidebar/active/assessment.svg"),
            credit_logo: require("@/assets/logos/principles-us.svg")
          }
        }
      ]
    },
    {
      path: "my-next-move",
      component: () =>
        import(
          "@/views/candidate/assessment/my_next_move/MyNextMoveWrapper.vue"
        ),
      meta: {
        user_state: [UserState.CANDIDATE],
        name: "nav-sidebar.my-next-move",
        icon: require("@/assets/icons/nav_sidebar/active/assessment.svg")
      },
      children: [
        {
          path: "",
          name: "my-next-move-assessment",
          component: () =>
            import("@/views/candidate/assessment/my_next_move/MyNextMove.vue"),
          meta: {
            user_state: [UserState.CANDIDATE],
            name: "nav-sidebar.my-next-move",
            icon: require("@/assets/icons/nav_sidebar/active/assessment.svg")
          }
        }
      ]
    }
  ]
};
